import {BACK_URL, getRequestOptions} from "./ApiService";

export async function login(user: string, password: string) {
    const requestOptions = getRequestOptions("GET");
    try {
        const response = await fetch(`${BACK_URL}/login?username=${user}&password=${password}`, requestOptions);
        if (response.ok) {
            return response.json();
        } else {
            return null;
        }
    } catch (e) {
        return null;

    }
}
