import React, {useContext} from 'react';
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import {Chip} from "@mui/material";
import LocalFireDepartmentIcon from "@mui/icons-material/LocalFireDepartment";
import LogoutIcon from "@mui/icons-material/Logout";
import {UserContext} from "../context/UserContext";
import HomePage from "../pages/HomePage";
import {saveToken} from "../service/TokenUtils";
import {MainContentContext} from "../context/MainContentContext";
import MuiAppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";

interface IProps {
}

function Header(props: IProps) {
    const {setContent} = useContext(MainContentContext);
    const {user, setUser} = useContext(UserContext);

    const logoutAction = () => {
        saveToken(undefined)
        setUser(undefined);
        setContent(<HomePage/>);
    }

    return (
        <MuiAppBar position="absolute">
            <Toolbar>
                <Box
                    onClick={() => setContent(<HomePage/>)}
                    sx={{
                        display: {xs: 'block', sm: 'block', md: 'none', lg: 'none', xl: 'none'},
                        '&:hover': {
                            cursor: 'pointer'
                        }
                    }}
                    component="img"
                    width={50}
                    height={50}
                    alt="SP Inventory Logo"
                    src="logo/logo_dark.svg"
                />
                <Box
                    onClick={() => setContent(<HomePage/>)}
                    sx={{
                        display: {xs: 'none', sm: 'none', md: 'block', lg: 'block', xl: 'block'},
                        '&:hover': {
                            cursor: 'pointer'
                        }
                    }}
                    component="img"
                    width={300}
                    height={50}
                    alt="SP Inventory Logo"
                    src="logo/baniere_dark.svg"
                />

                {user ?
                    <>
                        <Typography
                            onClick={() => setContent(<HomePage/>)}
                            component="h1"
                            variant="h6"
                            color="inherit"
                            noWrap
                            sx={{
                                flexGrow: 1,
                                '&:hover': {
                                    cursor: 'pointer'
                                }
                            }}
                        >
                            Accueil
                        </Typography>
                        <Chip label={user.caserne} avatar={<LocalFireDepartmentIcon/>}/>
                        <IconButton color="inherit"
                                    onClick={logoutAction}>
                            <LogoutIcon/>
                        </IconButton>
                    </>
                    :
                    <></>
                }
            </Toolbar>
        </MuiAppBar>
    );
}

export default Header;