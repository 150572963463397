import {MissingArticle, MissingEmplacement} from "../model/History";
import {Table, TableBody, TableCell, TableContainer, TableRow, Typography} from "@mui/material";
import React from "react";

export const missingEmplacement = (missingEmplacements: MissingEmplacement[]) => {
    if (missingEmplacements.length === 0) {
        return (
            <TableContainer>
                <Table>
                    <TableBody>
                        <TableRow>
                            <TableCell>
                                <Typography variant={"h6"}>Aucun manquant</Typography>
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        )
    }
    return (
        <TableContainer>
            <Table>
                <TableBody>
                    {missingEmplacements.map((missEmpl: MissingEmplacement) => (
                        <TableRow key={missEmpl.name}>
                            {missEmpl.missingArticles.map((missArticle: MissingArticle) => (
                                <TableCell>{`${missArticle.quantity} ${missArticle.name} dans ${missEmpl.name}`}</TableCell>
                            ))}
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    )
}
