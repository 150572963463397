import React from 'react';
import {IUser, IUsersInformations} from "../../model/IUsers";
import MenuItem from "@mui/material/MenuItem";
import {Form, Formik} from "formik";
import Select from "@mui/material/Select";
import SPButton from "../basics/SPButton";

interface IProps {
    users: IUsersInformations,
    userToDelete: (arg: string) => void
}

function UserChoiceForm({users, userToDelete}: IProps) {
    return (
        <React.Fragment>
            <Formik
                initialValues={{} as IUser}
                onSubmit={(values) => {
                    userToDelete(values.user)
                }}
            >
                {({handleChange}) => (
                    <Form autoComplete="off">
                        <Select className="user-select"
                                name="user"
                                fullWidth
                                label="Username"
                                onChange={handleChange}>
                            {users && users.users && users.users.map((u: IUser) => (
                                <MenuItem key={u.user} value={u.user} id={u.user}>{u.user}</MenuItem>
                            ))}
                        </Select>
                        <SPButton
                            fullWidth
                            child={"Supprimer"}
                        />
                    </Form>
                )
                }
            </Formik>
        </React.Fragment>
    );
}

export default UserChoiceForm;