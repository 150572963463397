import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import {Form, Formik} from "formik";
import React, {useEffect} from "react";
import {Vehicle} from "../../model/Vehicle";
import {listVehicules} from "../../service/VehiculeApi";
import SPButton from "../basics/SPButton";

interface IProps {
    id: (arg: string) => void,
    caserne: string
}

export default function VehiculeChoiceForm({id, caserne}: IProps) {
    const [vehicules, setVehicules] = React.useState<Vehicle[]>([]);

    useEffect(() => {
        listVehicules(caserne)
            .then((result) => setVehicules(result));
    }, [caserne, id]);

    return (
        <React.Fragment>
            <Formik
                initialValues={{} as Vehicle}
                onSubmit={(values) => {
                    id(values.id)
                }}
            >
                {({values, handleChange}) => (
                    <Form autoComplete="off">
                        <Select
                            className="custom-select"
                            name="id"
                            fullWidth
                            onChange={handleChange}
                            defaultValue={""}
                            sx={{
                                backgroundColor: "white"
                            }}
                        >
                            {vehicules &&
                                vehicules.map((v: Vehicle) => (
                                    <MenuItem key={v.id} value={v.id}>
                                        {v.name}
                                    </MenuItem>
                                ))}
                        </Select>
                        <SPButton
                            fullWidth
                            child={"Selectionner"}
                        />
                    </Form>
                )}
            </Formik>
        </React.Fragment>
    );
}
