import {createContext} from "react";


export const MainContentContext = createContext<
        {
            content: React.JSX.Element | undefined
            setContent: (content: React.JSX.Element) => void,
        }>({
            content: undefined,
            setContent: (content: React.JSX.Element) => {
            }
        }
    )
;