import React from 'react';
import {Checkbox, Table, TableBody, TableCell, TableContainer, TableHead} from "@mui/material";
import {StyledHeadTableRow, StyledTableCell, StyledTableRow} from "../../styles/StyledTable";
import {IUser, IUsersInformations} from "../../model/IUsers";
import {Form, Formik} from "formik";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import SPTextField from "../basics/SPTextField";
import SPButton from "../basics/SPButton";


interface IProps {
    usersAndInfo: IUsersInformations,
    updateRoles: (users: IUser[]) => void
}

function UserTabComponent({usersAndInfo, updateRoles}: IProps) {
    return (
        <React.Fragment>
            <Formik initialValues={usersAndInfo.users}
                    enableReinitialize={true}
                    onSubmit={(values) => {
                        updateRoles(values);
                    }}>
                {({values, handleChange}) => (
                    <Form>
                        <TableContainer key={"mainContainer"}>
                            <Table key={"mainTable"}>
                                <TableHead key={"mainHead"}>
                                    <StyledHeadTableRow key={"header"}>
                                        <StyledTableCell align={"center"} key={"header_user"}>Nom d'utilisateur</StyledTableCell>
                                        {usersAndInfo.roles.map(role => {
                                            return (
                                                <StyledTableCell align={"center"} key={role}>{role}</StyledTableCell>
                                            )
                                        })}
                                        <StyledTableCell align={"center"} key={"header_caserne"}>Caserne</StyledTableCell>
                                    </StyledHeadTableRow>
                                </TableHead>
                                <TableBody key={"tableBody"}>
                                    {values.map((user: IUser, i: number) => (
                                        <StyledTableRow key={"userRow" + i}>
                                            <TableCell align={"center"} key={"userCell" + i}>
                                                <SPTextField name={`${i}.user`}
                                                             id={`${i}.user`}
                                                             value={user.user}
                                                             disabled
                                                             onChange={handleChange}/>
                                            </TableCell>
                                            {
                                                usersAndInfo.roles.map((role: string, roleIndex: number) => {
                                                    return (
                                                        <TableCell align={"center"} key={"roleCell" + i + roleIndex} role={"group"}>
                                                            <Checkbox onChange={handleChange}
                                                                      name={`${i}.roles`}
                                                                      checked={user.roles.includes(role)}
                                                                      value={role}
                                                            />
                                                        </TableCell>
                                                    )
                                                })
                                            }
                                            {
                                                <TableCell align={"center"} key={"caserneCell" + i}>
                                                    <Select
                                                        fullWidth
                                                        key={"select-caserne"}
                                                        name={`${i}.caserne`}
                                                        value={user.caserne}
                                                        onChange={handleChange}
                                                    >
                                                        {
                                                            usersAndInfo.casernes && usersAndInfo.casernes.map((caserneName: string, caserneId: number) => (
                                                                <MenuItem key={"menu-caserne-" + i + caserneId}
                                                                          value={caserneName}>{caserneName}</MenuItem>
                                                            ))
                                                        }
                                                    </Select>
                                                </TableCell>
                                            }
                                        </StyledTableRow>
                                    ))}
                                </TableBody>
                            </Table>
                            <SPButton
                                fullWidth
                                child={"Sauvegarder"}/>
                        </TableContainer>
                    </Form>
                )}
            </Formik>

        </React.Fragment>
    );
}

export default UserTabComponent;