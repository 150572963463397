import React from 'react';
import {Control, useFieldArray, UseFormRegister} from 'react-hook-form';
import {Box, Typography} from "@mui/material";
import ArticleArray from "./ArticleArray";
import {FormValues} from "../../../types/VehicleFormValues";
import AddIcon from "@mui/icons-material/Add";
import {Emplacement} from "../../../model/Vehicle";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import SPTextField from "../../basics/SPTextField";
import SPButton from "../../basics/SPButton";

function EmplacementsArray(
    {
        isEditable,
        control,
        register,
    }: {
        isEditable?: boolean;
        control: Control<FormValues>;
        register: UseFormRegister<FormValues>;
    }) {
    const {fields, append, remove} = useFieldArray({
        control,
        name: "vehicle.emplacements"
    });
    return (
        <>
            {fields.map((emplacement, index) => {
                return (
                    <Box
                        key={emplacement.name}
                        sx={{
                            borderRadius: "8px",
                            mx: "auto",
                            p: 2,
                            mt: 1,
                            mb: 2,
                            backgroundColor: (theme) => index % 2 == 0 ? theme.palette.grey[300] : theme.palette.grey[200],
                            textAlign: "center",
                            boxShadow: 5
                        }}
                    >
                        {isEditable ?
                            <SPTextField
                                r={register(`vehicle.emplacements.${index}.name`)}
                                label={"Nom de l'emplacement"}
                                id={"emplacements" + index}
                            />
                            :
                            <Typography variant="h6">{emplacement.name}</Typography>
                        }
                        <ArticleArray
                            isEditable={isEditable}
                            emplacementIndex={index}
                            {...{control, register}}
                        />
                        {isEditable ?
                            <>
                                <IconButton onClick={() => remove(index)}>
                                    <DeleteIcon/>Supprimer emplacement<DeleteIcon/>
                                </IconButton>
                            </>
                            :
                            <></>
                        }
                    </Box>
                )
            })}
            {isEditable ?
                <SPButton
                    type="button"
                    fullWidth
                    onClick={() => append({} as Emplacement)}
                    child={<AddIcon/>}
                />
                :
                <></>
            }
        </>
    );
}

export default EmplacementsArray;