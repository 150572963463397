import React, {useContext} from 'react';
import {login} from "../service/LoginApi";
import {saveToken} from "../service/TokenUtils";
import {manageUser, UserContext} from "../context/UserContext";
import {sha256} from 'js-sha256';
import {Box, Stack, Typography} from '@mui/material';
import SPTextField from '../components/basics/SPTextField';
import SPButton from "../components/basics/SPButton";
import { useSnackbar } from 'notistack';

interface LoginProps {
}

function Login(props: LoginProps) {
    const {setUser} = useContext(UserContext);
    const { enqueueSnackbar } = useSnackbar();


    async function handleLogin(event: React.FormEvent<HTMLFormElement>) {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        const userValue = data.get("user") as string;
        const passwordValue = data.get("password") as string;
        const user = {"user": userValue, "password": sha256(passwordValue)};

        const response = await login(user.user, user.password);
        if (response) {
            saveToken(response.token);
            setUser(manageUser(response.token))
        } else {
            enqueueSnackbar("Nom d'utilisateur ou mot de passe incorrect", {variant: "warning"})
            saveToken(undefined);
            setUser(undefined);
        }
    }

    return (
        <Stack
            direction="row"
            justifyContent="center"
            alignItems="center"
            sx={{height: "80vh"}}
        >
            <Box component="form"
                 onSubmit={handleLogin}
                 noValidate
                 boxShadow={10}
                 p={5}
                 sx={(theme) => ({
                     mt: 1,
                     backgroundColor: theme.palette.grey[200],
                     [theme.breakpoints.down("md")]: {
                         width: "100%",
                     },
                 })}>
                <Stack
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                >
                    <Typography variant={"h2"}>Bienvenue</Typography>
                    <Box
                        component="img"
                        width={300}
                        height={50}
                        alt="SP Inventory Logo"
                        src="logo/baniere_dark.svg"
                    />

                </Stack>
                <SPTextField
                    id="user"
                    label="Nom d'utilisateur"
                    name="user"
                    required
                />
                <SPTextField
                    name="password"
                    label="Mot de passe"
                    type="password"
                    id="password"
                    required
                />
                <SPButton
                    fullWidth
                    child={"Se connecter"}
                />
            </Box>
        </Stack>
    );
}

export default Login;