import {Vehicle} from "../model/Vehicle";
import {BACK_URL, getRequestOptions} from "./ApiService";

export async function submitInventaire(vehicle: Vehicle) {
    try {
        const response = await fetch(
            `${BACK_URL}/inventaire`,
            getRequestOptions("POST", vehicle)
        );
        if (response.ok) {
            return response.json()
        } else {
            return null;
        }
    } catch (e) {
        return null;
    }
}

export async function getInventaireById(id: string) {
    try {
        const response = await fetch(
            `${BACK_URL}/inventaire?id=${id}`,
            getRequestOptions("GET", null)
        );
        if (response.ok) {
            return response.json()
        } else {
            return null;
        }
    } catch (e) {
        return null;
    }
}

export async function deleteInventaireById(id: string) {
    try {
        const response = await fetch(
            `${BACK_URL}/inventaire?id=${id}`,
            getRequestOptions("DELETE", null)
        );
        if (response.ok) {
            return response
        } else {
            return null;
        }
    } catch (e) {
        return null;
    }
}