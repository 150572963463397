import {Card, CardActionArea, CardContent, Typography} from "@mui/material";
import React, {useContext} from "react";
import {MainContentContext} from "../../context/MainContentContext";

interface AdminCardProps {
    title: string,
    element: React.JSX.Element
    icon: React.JSX.Element
}

function AdminCard(props: AdminCardProps) {
    const {title, element, icon} = props;
    const {setContent} = useContext(MainContentContext)
    return (
        <Card
            sx={{
                backgroundColor: (theme) => theme.palette.primary.main,
                textAlign: "center",
                verticalAlign: "middle",
                boxShadow: 10
            }}
            onClick={() => setContent(element)}
        >
            <CardActionArea>
                <CardContent>
                    {icon}
                    <Typography variant="h5">
                        {title}
                    </Typography>
                </CardContent>
            </CardActionArea>
        </Card>
    )
}

export default AdminCard;