import {styled} from "@mui/material/styles";
import {TableCell, tableCellClasses, TableRow} from "@mui/material";

export const StyledTableCell = styled(TableCell)(({theme}) => ({
    [`&.${tableCellClasses.head}`]: {
        color: theme.palette.common.black,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

export const StyledHeadTableRow = styled(TableRow)(({theme}) => ({
    backgroundColor: theme.palette.primary.main,
}));


export const StyledTableRow = styled(TableRow)(({theme}) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));
