import {BACK_URL, getRequestOptions} from "./ApiService";
import {IUser} from "../model/IUsers";

export async function getUsers() {
    try {
        const response = await fetch(
            `${BACK_URL}/users`,
            getRequestOptions("GET", null)
        );
        if (response.ok) {
            return response.json()
        } else {
            return null;
        }
    } catch (e) {
        return null;
    }
}

export async function updateUsersRoles(users: IUser[]) {
    try {
        const response = await fetch(`${BACK_URL}/users`, getRequestOptions("PUT", users));
        if (response.ok) {
            return response.json()
        } else {
            return null;
        }
    } catch (e) {
        return null;
    }
}

export async function addUser(user: any) {
    try {
        const response = await fetch(
            `${BACK_URL}/user`,
            getRequestOptions("POST", user)
        );
        if (response.ok) {
            return response.json()
        } else {
            return null;
        }
    } catch (e) {
        return null;
    }
}

export async function removeUser(username: string) {
    try {
        const response = await fetch(
            `${BACK_URL}/user?username=${username}`,
            getRequestOptions("DELETE", null)
        );
        if (response.ok) {
            return response
        } else {
            return null;
        }
    } catch (e) {
        return null;
    }
}
