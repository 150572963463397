import {getToken} from "./TokenUtils";
import {decodeToken} from "react-jwt";
import {IUserToken} from "../model/IUserToken";

export const BACK_URL = process.env.REACT_APP_API_PROXY;

export async function refreshToken(refreshToken: string) {
    return await fetch(BACK_URL + "/refresh?refreshToken=" + refreshToken)
        .then(response => {
            if (!response.ok) {
                return null;
            }
            return response;
        })
        .catch(error => {
            console.error('Error:', error);
            return null;
        });
}

export function getRequestOptions(method: string, body?: any) {
    let token = getToken();
    if (body) {
        return {
            method: method,
            headers: {
                "Content-Type": "application/json",
                'Authorization': 'Bearer ' + token
            },
            body: JSON.stringify(body, null, 2)
        }
    }
    return {
        method: method,
        headers: {
            "Content-Type": "application/json",
            'Authorization': 'Bearer ' + token
        }
    }
}
