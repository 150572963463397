import React from 'react';
import {IUsersInformations} from "../../model/IUsers";
import UserChoiceForm from "../formulaire/UserChoiceForm";
import {DialogActions, DialogContentText, DialogTitle} from "@mui/material";
import DialogContent from "@mui/material/DialogContent";
import Dialog from "@mui/material/Dialog";
import SPButton from "../basics/SPButton";


interface IProps {
    users: IUsersInformations,
    deleteUser: (arg: string) => void
}

function DeleteUserComponent({users, deleteUser}: IProps) {
    const [openDialog, setOpenDialog] = React.useState(false);
    const [user, setUser] = React.useState<string>();

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    const userToDelete = (user: string): void => {
        setUser(user);
        setOpenDialog(true);
    }

    const doDelete = (): void => {
        if (user !== undefined) {
            deleteUser(user);
        }
        setOpenDialog(false);
    }

    return (
        <React.Fragment>
            <UserChoiceForm users={users} userToDelete={userToDelete}/>

            <Dialog
                open={openDialog}
                onClose={handleCloseDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Voulez-vous vraiment supprimer cet utilisateur ?"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Cette opération est irréversible!
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <SPButton onClick={handleCloseDialog} child={"Non j'ai changé d'avis"}/>
                    <SPButton onClick={doDelete} child={"OUI je veux supprimer"}/>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
}

export default DeleteUserComponent;