export const saveToken = (userToken: string | undefined) => {
    if (userToken === undefined) {
        localStorage.removeItem('token');
        //setUser(undefined);
    } else {
        localStorage.setItem('token', userToken);
        //setUser(manageUser(userToken))
    }
};

export const getToken = () => {
    return localStorage.getItem('token');
}
