import React from 'react';
import {Control, useFieldArray, UseFormRegister} from "react-hook-form";
import {Grid, Typography} from "@mui/material";
import {FormValues} from "../../../types/VehicleFormValues";
import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";
import {Article} from "../../../model/Vehicle";
import AddIcon from "@mui/icons-material/Add";
import SPTextField from "../../basics/SPTextField";
import SPButton from "../../basics/SPButton";

function ArticleArray(
    {
        emplacementIndex,
        isEditable,
        control,
        register
    }: {
        emplacementIndex: number;
        isEditable?: boolean;
        control: Control<FormValues>;
        register: UseFormRegister<FormValues>;
    }) {
    const {fields, remove, append} = useFieldArray({
        control,
        name: `vehicle.emplacements.${emplacementIndex}.articles` as const,
        rules: {minLength: 1}
    });
    return (
        <>
            {fields.map((article, index) => {
                return (
                    <Grid
                        key={article.name + article.quantity}
                        container
                        spacing={2}
                        direction={"row"}
                        justifyContent={"center"}
                        alignItems={"center"}
                        mb={2}
                        mt={1}
                    >
                        <Grid
                            item
                            xs
                            sm
                            md
                            alignItems={"flex-end"}
                            justifyContent={"flex-end"}
                        >
                            {isEditable ?
                                <SPTextField
                                    r={register(`vehicle.emplacements.${emplacementIndex}.articles.${index}.name`)}
                                    id={"emplacements/" + emplacementIndex + "/articles/" + index + "/name"}
                                />
                                :
                                <Typography>{article.name}</Typography>
                            }
                        </Grid>
                        <Grid
                            item
                            xs={6}
                            sm={6}
                            md={6}
                        >
                            <SPTextField
                                r={register(`vehicle.emplacements.${emplacementIndex}.articles.${index}.quantity`)}
                                type="number"
                                id={"emplacements/" + emplacementIndex + "/articles/" + index + "/quantity"}
                            />
                        </Grid>
                        {isEditable ?
                            <Grid
                                item
                                xs={1}
                                sm={1}
                                md={1}
                            >
                                <IconButton onClick={() => remove(index)}>
                                    <DeleteIcon/>
                                </IconButton>
                            </Grid>
                            :
                            <></>
                        }
                    </Grid>
                )
            })}
            {isEditable ?
                <SPButton
                    child={<AddIcon/>}
                    fullWidth
                    type={"button"}
                    onClick={() => append({name: "", quantity: 1} as Article)}
                />
                :
                <></>
            }
        </>
    );
}

export default ArticleArray;