import {BACK_URL, getRequestOptions} from "./ApiService";

export const listVehicules = async (caserne: string) => {
    try {
        const response = await fetch(
            `${BACK_URL}/inventaire?caserne=${caserne}`,
            getRequestOptions("GET", null)
        );
        if (response.ok) {
            return response.json()
        } else {
            return null;
        }
    } catch (e) {
        return null;
    }
}
