import {Card, CardActionArea, CardContent, Typography} from "@mui/material";
import React, {useContext} from "react";
import {Vehicle} from "../../model/Vehicle";
import {MainContentContext} from "../../context/MainContentContext";
import VehicleContent from "../VehicleContent";
import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";

interface VehiculeCardProps {
    vehicle: Vehicle
}

function VehicleCard(props: VehiculeCardProps) {
    const {vehicle} = props;
    const {setContent} = useContext(MainContentContext);

    return (
        <Card
            sx={{
                backgroundColor: (theme) => theme.palette.primary.main,
                textAlign: "center",
                verticalAlign: "middle",
                boxShadow: 10
            }}
            onClick={() => setContent(<VehicleContent vehicle={vehicle}/>)}
        >
            <CardActionArea>
                <CardContent>
                    <DirectionsCarIcon/>
                    <Typography variant="h5" component="div">
                        {vehicle.name}
                    </Typography>
                </CardContent>
            </CardActionArea>
        </Card>
    )
}

export default VehicleCard