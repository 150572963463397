import {createContext} from "react";
import {IUserToken} from "../model/IUserToken";
import {decodeToken} from "react-jwt";
import {refreshToken} from "../service/ApiService";
import {saveToken} from "../service/TokenUtils";


export const UserContext = createContext<{
    user: IUserToken | undefined,
    setUser: (user: IUserToken | undefined) => void
}>({
    user: undefined,
    setUser: (user: IUserToken | undefined) => {
    }
});

export function manageUser(token: string | null) {
    if (token === null) return undefined;

    let u = decodeToken<IUserToken>(token);
    if (u === null) {
        return undefined;
    }
    if (u.exp < Math.floor(Date.now() / 1000)) {
        if (u.refreshToken === undefined) return undefined;
        refreshToken(u.refreshToken)
            .then(response => {
                if (response === null) return undefined;
                if (!response.ok) return undefined;
                response.json()
                    .then(token => {
                        saveToken(token.token);
                        let newUser = decodeToken<IUserToken>(token);
                        return newUser === null ? undefined : newUser;
                    })
            })
    }
    return u;
}