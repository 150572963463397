import React from "react";
import {Vehicle} from "../model/Vehicle";
import VehiculeChoiceForm from "../components/formulaire/VehiculeChoiceForm";
import VehiculeContentForm from "../components/formulaire/VehiculeContentForm";
import PageHeader from "../components/PageHeader";
import {getInventaireById} from "../service/InventaireApi";


interface IProps {
    caserne: string
}

export default function ModifyVehiclePage(props: IProps) {
    const {caserne} = props
    const [vehicule, setVehicule] = React.useState<Vehicle>();

    const choseid = (id: string): void => {
        getInventaireById(id)
            .then(result => setVehicule(result[0]))
    };

    return (
        <>
            <PageHeader message={"Modification"}/>
            <VehiculeChoiceForm id={choseid} caserne={caserne}/>
            {
                vehicule && <VehiculeContentForm vehicle={vehicule} snackMessage={"Inventaire modifié"}/>
            }
        </>
    )
}
