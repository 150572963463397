import React, {useContext, useEffect} from "react";
import {Vehicle} from "../../model/Vehicle";
import {useForm} from "react-hook-form";
import {FormValues} from "../../types/VehicleFormValues";
import EmplacementsArray from "./vehicule_detail/EmplacementsArray";
import {submitInventaire} from "../../service/InventaireApi";
import HomePage from "../../pages/HomePage";
import {MainContentContext} from "../../context/MainContentContext";
import SPButton from "../basics/SPButton";
import SPTextField from "../basics/SPTextField";
import {useSnackbar} from "notistack";

interface IProps {
    vehicle: Vehicle,
    snackMessage: string
}

export default function VehiculeContentForm(props: IProps) {
    const {vehicle, snackMessage} = props;
    const {setContent} = useContext(MainContentContext);
    const {enqueueSnackbar} = useSnackbar();
    const defaultValues = {
        vehicle: vehicle
    }
    const {
        register,
        control,
        reset,
        getValues,
        setValue,
        handleSubmit
    } = useForm<FormValues>({
        defaultValues,
        mode: "onBlur"
    });

    useEffect(() => {
        reset({
            vehicle: vehicle
        })
    }, [reset, vehicle]);

    async function submitForm(vehicle: FormValues) {
        const response = await submitInventaire(vehicle.vehicle)
        if (response) {
            enqueueSnackbar(snackMessage, {variant: "success"});
            setContent(<HomePage/>);
        } else {
            enqueueSnackbar("Une erreur est survenue!", {variant: "warning"});
        }
    }

    return (
        <>
            <form onSubmit={handleSubmit(submitForm)}>
                <SPTextField
                    r={register("vehicle.name")}
                    id="name"
                />
                <EmplacementsArray
                    isEditable
                    {...{control, register, getValues, setValue}}
                />
                <SPButton
                    fullWidth
                    child={"Envoyer"}
                />
            </form>
        </>
    );
}
