import React, {useContext, useEffect} from "react";
import {Vehicle} from "../model/Vehicle";
import {useForm} from "react-hook-form";
import EmplacementsArray from "./formulaire/vehicule_detail/EmplacementsArray";
import {FormValues} from "../types/VehicleFormValues";
import {submitHistory} from "../service/HistoryApi";
import HomePage from "../pages/HomePage";
import {MainContentContext} from "../context/MainContentContext";
import SPTextField from "./basics/SPTextField";
import SPButton from "./basics/SPButton";
import {useSnackbar} from "notistack";


interface IProps {
    vehicle: Vehicle,
}

export default function VehicleContent({vehicle}: IProps) {
    const {setContent} = useContext(MainContentContext);
    const {enqueueSnackbar} = useSnackbar();
    const defaultValues = {
        user: "",
        vehicle: vehicle
    }
    const {
        register,
        control,
        reset,
        handleSubmit
    } = useForm<FormValues>({
        defaultValues,
        mode: "onSubmit"
    });

    useEffect(() => {
        reset({
            user: "",
            vehicle: vehicle
        })
    }, [reset, vehicle]);

    async function submitForm(values: FormValues) {
        if (values.user === "") {
            enqueueSnackbar("Vous devez saisir un nom", {variant: "warning"})
            return;
        }
        const response = await submitHistory(values.user, values.vehicle);
        if (response) {
            enqueueSnackbar("Inventaire sauvegardé", {variant: "success"})
            setContent(<HomePage/>);
        } else {
            enqueueSnackbar("Erreur durant la sauvegarde, merci de recommencer", {variant: "warning"})
        }
    }

    return (
        <>
            <form onSubmit={handleSubmit(submitForm)}>
                <SPTextField
                    r={register("vehicle.name")}
                    id="id"
                    disabled
                />
                <SPTextField
                    r={register("user")}
                    label="Nom"
                    id={"user"}
                />
                <div>
                    <EmplacementsArray
                        {...{control, register}}
                    />
                </div>
                <SPButton
                    fullWidth
                    child={"Envoyer"}
                />
            </form>
        </>
    );
}
