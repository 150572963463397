import React from 'react';
import {FormControl} from "@mui/material";
import {UserLogin} from "../../model/UserLogin";
import SPTextField from "../basics/SPTextField";
import SPButton from "../basics/SPButton";

interface IProps {
    user: UserLogin,
    create: () => void
}

function CreateNewUserComponent({user, create}: IProps) {

    return (
        <React.Fragment>
            <FormControl component={"form"} fullWidth>
                <SPTextField
                    id="create-username"
                    margin={"dense"}
                    label="Username"
                    onChange={e => user.user = e.target.value}/>
                <SPTextField id="create-password"
                             margin={"dense"}
                             label="Password" type={"password"} onChange={e => user.password = e.target.value}/>
                <SPButton onClick={create}
                          fullWidth
                          child={"Ajouter"}
                />
            </FormControl>
        </React.Fragment>
    );
}

export default CreateNewUserComponent;