import React from 'react';
import {Typography} from "@mui/material";
import {Stack} from "@mui/system";

interface IProps {
    message: string
}
function PageHeader({message}: IProps) {
    return (
        <Stack direction='row' justifyContent='center'>
            <Typography variant={"h2"}>{message}</Typography>
        </Stack>
    );
}

export default PageHeader;