import React from 'react';
import Button from "@mui/material/Button";

interface SPButtonProps {
    fullWidth?: boolean,
    type?: "button" | "submit" | "reset" | undefined,
    child: React.JSX.Element | string,
    onClick?: () => void
}

export default function SPButton(props: SPButtonProps) {
    const {fullWidth = false,type="submit", child, onClick} = props;
    return (
        <Button
            type={type}
            fullWidth={fullWidth}
            variant="contained"
            onClick={onClick}
            sx={{mt: 3, mb: 2}}
        >
            {child}
        </Button>
    );
}