import {TextField} from '@mui/material';
import React from 'react';
import {UseFormRegisterReturn} from "react-hook-form/dist/types/form";

interface TextFieldProps {
    id: string,
    label?: string,
    name?: string,
    type?: string,
    disabled?: boolean,
    required?: boolean,
    r?: UseFormRegisterReturn<string>
    margin?: 'dense' | 'normal' | 'none',
    onChange?: (e: any) => any,
    value?: string
}

export default function SPTextField(props: TextFieldProps) {
    const {
        id,
        label,
        name,
        type = "text",
        disabled = false,
        required = false,
        r,
        margin = "normal",
        onChange,
        value
    } = props;

    return (
        <TextField
            margin={margin}
            required={required}
            fullWidth
            id={id}
            label={label}
            name={name}
            type={type}
            disabled={disabled}
            onChange={onChange}
            value={value}
            {...r}
            sx={{
                backgroundColor: "white"
            }}
        />
    );
}
