import React from 'react';
import {Vehicle} from '../model/Vehicle';
import VehiculeContentForm from '../components/formulaire/VehiculeContentForm';
import PageHeader from "../components/PageHeader";

interface IProps {
    caserne: string | undefined
}

export default function CreateVehiclePage(props: IProps) {
    const {caserne} = props
    const initialValues: Vehicle = {caserne: caserne} as Vehicle;

    return (
        <>
            <PageHeader message={"Ajout de véhicule"}/>
            <VehiculeContentForm vehicle={initialValues} snackMessage={"Inventaire sauvegardé"}/>
        </>
    )
}
