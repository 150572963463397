import React, {useContext, useEffect} from 'react';
import {UserContext} from "../context/UserContext";
import {Grid, Typography} from "@mui/material";
import {listVehicules} from "../service/VehiculeApi";
import {Vehicle} from "../model/Vehicle";
import CreateVehiclePage from "./CreateVehiclePage";
import AddIcon from "@mui/icons-material/Add";
import ModifyVehiclePage from "./ModifyVehiclePage";
import CreateIcon from "@mui/icons-material/Create";
import VehicleHistoryPage from "./VehicleHistoryPage";
import HistoryIcon from "@mui/icons-material/History";
import DeleteVehiclePage from "./DeleteVehiclePage";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import SuperAdminPage from "./SuperAdminPage";
import PersonIcon from "@mui/icons-material/Person";
import VehicleCard from "../components/card/VehicleCard";
import AdminCard from "../components/card/AdminCard";
import Login from "./Login";

function HomePage() {
    const {user} = useContext(UserContext);
    const [vehicles, setVehicles] = React.useState<Vehicle[]>();

    useEffect(() => {
        if (user === undefined) return;
        listVehicules(user.caserne)
            .then(result => setVehicles(result))
    }, [user])

    if (user === undefined) {
        return (<Login/>)
    }

    return (
        <>
            <Typography gutterBottom mt={4} variant="h3" textAlign={"center"}>
                Liste des camions
            </Typography>
            <Grid container
                  spacing={0}
                  alignItems={"center"}
                  justifyContent={"center"}
            >
                {vehicles && vehicles.map((v) => {
                    return (
                        <Grid key={v.id}
                              item
                              xs={6}
                              sm={6}
                              md={6}
                              lg={2}
                              xl={2}
                              mr={4}
                              mb={4}>
                            <VehicleCard vehicle={v}/>
                        </Grid>
                    )
                })}
            </Grid>
            {user.role.includes("ADMIN") ?
                <>
                    <Typography gutterBottom mt={4} variant="h3" textAlign={"center"}>
                        Administration des inventaires
                    </Typography>
                    <Grid container spacing={0}
                          alignItems={"center"}
                          justifyContent={"center"}
                    >
                        <Grid item xs={6} sm={6} md={6} lg={2} xl={2} mr={4} mb={4}>
                            <AdminCard
                                title={"Ajouter un véhicule"}
                                element={<CreateVehiclePage caserne={user.caserne}/>}
                                icon={<AddIcon/>}
                            />
                        </Grid>

                        <Grid item xs={6} sm={6} md={6} lg={2} xl={2} mr={4} mb={4}>
                            <AdminCard
                                title={"Modifier un véhicule"}
                                element={<ModifyVehiclePage caserne={user.caserne}/>}
                                icon={<CreateIcon/>}
                            />
                        </Grid>

                        <Grid item xs={6} sm={6} md={6} lg={2} xl={2} mr={4} mb={4}>
                            <AdminCard
                                title={"Historique d'un véhicule"}
                                element={<VehicleHistoryPage caserne={user.caserne}/>}
                                icon={<HistoryIcon/>}
                            />
                        </Grid>

                        <Grid item xs={6} sm={6} md={6} lg={2} xl={2} mr={4} mb={4}>
                            <AdminCard
                                title={"Suppression d'un véhicule"}
                                element={<DeleteVehiclePage caserne={user.caserne}/>}
                                icon={<DeleteForeverIcon/>}
                            />
                        </Grid>
                    </Grid>
                </>
                :
                <></>
            }
            {user.role.includes("SUPER_ADMIN") ?
                <>
                    <Typography gutterBottom mt={4} variant="h3" textAlign={"center"}>
                        Administration des utilisateurs
                    </Typography>
                    <Grid container
                          spacing={0}
                          alignItems={"center"}
                          justifyContent={"center"}
                    >
                        <Grid item mr={4} mb={4}>
                            <AdminCard
                                title={"Administration"}
                                element={<SuperAdminPage/>}
                                icon={<PersonIcon/>}
                            />
                        </Grid>
                    </Grid>
                </>
                :
                <></>
            }
        </>
    );
}

export default HomePage;