import {sha256} from "js-sha256";

export class UserLogin {
    get password(): string {
        return this._password;
    }

    set password(value: string) {
        this._password = sha256(value);
    }

    user: string;
    private _password: string;

    constructor(username: string, password: string) {
        this.user = username;
        this._password = password;
    }

}