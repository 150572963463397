import React, {useContext} from 'react';
import {DialogActions, DialogContentText, DialogTitle} from "@mui/material";
import VehiculeChoiceForm from "../components/formulaire/VehiculeChoiceForm";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import PageHeader from "../components/PageHeader";
import {deleteInventaireById} from "../service/InventaireApi";
import HomePage from './HomePage';
import {MainContentContext} from "../context/MainContentContext";
import SPButton from "../components/basics/SPButton";
import {useSnackbar} from "notistack";

interface IProps {
    caserne: string,
}

export default function DeleteVehiclePage(props: IProps) {
    const {caserne} = props;
    const {setContent} = useContext(MainContentContext);
    const {enqueueSnackbar} = useSnackbar();
    const [vehicleId, setVehicleId] = React.useState<string>();
    const [openDialog, setOpenDialog] = React.useState(false);

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    const confirmDelete = (id: string) => {
        setVehicleId(id);
        setOpenDialog(true);
    }

    async function doDelete() {
        //vehicleId toujours avec une valeur car appelé après le confirmDelete
        const response = await deleteInventaireById(vehicleId as string)
        if (response) {
            enqueueSnackbar("Inventaire supprimé", {variant: "success"});
            setContent(<HomePage/>);
        } else {
            enqueueSnackbar("Une erreur est survenue, l'inventaire n'est pas supprimé", {variant: "warning"})
        }
    }


    return (
        <>
            <PageHeader message={"Suppression d'un inventaire"}/>
            <VehiculeChoiceForm id={confirmDelete} caserne={caserne}/>

            <Dialog
                open={openDialog}
                onClose={handleCloseDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Voulez-vous vraiment supprimer cet inventaire ?"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Cette opération est irréversible!
                        Cette opération supprime également les historiques asociés !
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <SPButton onClick={handleCloseDialog} child={"Non j'ai changé d'avis"}/>
                    <SPButton onClick={doDelete} child={"OUI je veux supprimer"}/>
                </DialogActions>
            </Dialog>
        </>
    );
}

