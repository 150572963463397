import React from "react";
import VehiculeChoiceForm from "../components/formulaire/VehiculeChoiceForm";
import {History} from "../model/History";
import {Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@mui/material";
import {StyledTableCell, StyledTableRow} from "../styles/StyledTable";
import PageHeader from "../components/PageHeader";
import {getHistoryById} from "../service/HistoryApi";
import {missingEmplacement} from "../components/MissingEmplacement";

interface IProps {
    caserne: string
}

export default function VehicleHistoryPage({caserne}: IProps) {
    const [history, setHistory] = React.useState<History[]>();

    async function choseid(id: string) {
        const response = await getHistoryById(id);
        if (response) {
            setHistory(response);
        }
    }

    if (history === undefined) {
        return (
            <React.Fragment>
                <PageHeader message={"Historique"}/>
                <VehiculeChoiceForm id={choseid} caserne={caserne}/>
            </React.Fragment>
        );
    }

    return (
        <React.Fragment>
            <PageHeader message={"Historique"}/>
            <VehiculeChoiceForm id={choseid} caserne={caserne}/>
            <div style={{height: '100vh', width: '100%'}}>
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <StyledTableCell>Nom</StyledTableCell>
                                <StyledTableCell>Date de l'inventaire</StyledTableCell>
                                <StyledTableCell>Manquants</StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {history.map((h: History) => (
                                <StyledTableRow key={h.id}>
                                    <TableCell>{h.userId}</TableCell>
                                    <TableCell>{h.date}</TableCell>
                                    {missingEmplacement(h.missingEmplacements)}
                                </StyledTableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
        </React.Fragment>
    );
}
