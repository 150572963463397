import {createTheme} from "@mui/material/styles";
import {grey, red} from "@mui/material/colors";

export const MD_THEME = createTheme({
    palette: {
        primary: {
            main: grey[100],
            dark: red[900]
        }
    }
});