import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import {ThemeProvider} from '@mui/material/styles';
import Toolbar from '@mui/material/Toolbar';
import * as React from 'react';
import {useEffect} from 'react';
import {IUserToken} from "./model/IUserToken";
import Header from "./components/Header";
import {MD_THEME} from './styles/Theme';
import {manageUser, UserContext} from './context/UserContext';
import HomePage from "./pages/HomePage";
import {getToken} from "./service/TokenUtils";
import {MainContentContext} from "./context/MainContentContext";
import {SnackbarProvider} from "notistack";

export interface SnackbarMessage {
    message: string;
    key: number;
}

function DashboardContent() {
    let token = getToken();
    const [content, setContent] = React.useState<React.JSX.Element>(<HomePage/>);
    const [user, setUser] = React.useState<IUserToken | undefined>(manageUser(token));

    useEffect(() => {
        const u = manageUser(token)
        if (u === undefined) {
            setUser(undefined);
            return;
        }
        setUser(u);
        if (content === undefined) {
            setContent(<HomePage/>)
        }
    }, [token, content])


    return (
        <ThemeProvider theme={MD_THEME}>
            <UserContext.Provider value={{user, setUser}}>
                <MainContentContext.Provider value={{content, setContent}}>
                    <SnackbarProvider maxSnack={1}
                                      anchorOrigin={{
                                          vertical: 'top',
                                          horizontal: 'center',
                                      }}
                    >
                        <Box sx={{display: 'flex'}}>
                            <CssBaseline/>
                            <Header/>
                            <Box
                                component="main"
                                sx={{
                                    backgroundColor: (theme) =>
                                        theme.palette.mode === 'light'
                                            ? "white"
                                            : theme.palette.grey[900],
                                    flexGrow: 1,
                                    height: '100vh',
                                    overflow: 'auto',
                                }}
                            >
                                <Toolbar/>
                                <Container maxWidth="lg" sx={{mt: 4, mb: 4}}>
                                    {content}
                                </Container>
                            </Box>
                        </Box>
                    </SnackbarProvider>
                </MainContentContext.Provider>
            </UserContext.Provider>
        </ThemeProvider>
    );
}

export default function App() {
    return <DashboardContent/>;
}