import React, {useContext, useEffect} from 'react';
import {Divider, Typography} from "@mui/material";
import UserTabComponent from "../components/super_admin_content/UserTabComponent";
import {IUser, IUsersInformations} from "../model/IUsers";
import CreateNewUserComponent from "../components/super_admin_content/CreateNewUserComponent";
import Box from "@mui/material/Box";
import PageHeader from "../components/PageHeader";
import DeleteUserComponent from "../components/super_admin_content/DeleteUserComponent";
import {addUser, getUsers, removeUser, updateUsersRoles} from "../service/UserApi";
import {UserContext} from "../context/UserContext";
import {UserLogin} from "../model/UserLogin";
import {useSnackbar} from "notistack";

export default function SuperAdminPage() {
    const {enqueueSnackbar} = useSnackbar();
    const [users, setUsers] = React.useState<IUsersInformations>({roles: [], casernes: [], users: []});
    const [userLogin] = React.useState<UserLogin>(new UserLogin("", ""));
    const user = useContext(UserContext);

    useEffect(() => {
        getUsers()
            .then(res => setUsers(res))
    }, [user]);

    async function updateRoles(u: IUser[]) {
        const response = await updateUsersRoles(u);
        if (response) {
            enqueueSnackbar("Utilisateurs mis à jour", {variant: "success"});
        } else {
            enqueueSnackbar("Erreur durant la modification des droits", {variant: "warning"});
        }
    }

    async function createUser() {
        if (userLogin.user !== "" && userLogin.password !== "") {
            const response = await addUser({
                "user": userLogin.user,
                "password": userLogin.password
            })

            if (response) {
                enqueueSnackbar("Utilisateur créé", {variant: "success"});
            } else {
                enqueueSnackbar("Erreur durant la création du nouvel utilisateur", {variant: "warning"});
            }
        }
    }

    async function doDelete(username: string) {
        const response = await removeUser(username);
        if (response) {
            enqueueSnackbar("Utilisateur supprimé", {variant: "success"});
            return true;
        } else {
            enqueueSnackbar("Erreur durant la suppression de l'utilisateur", {variant: "warning"});
            return false;
        }
    }

    return (
        <React.Fragment>
            <PageHeader message={"Gestion des utilisateurs"}/>
            <Divider sx={{my: 1}}>
                <Typography variant={"h5"}>Liste des utilisateurs</Typography>
            </Divider>
            <Box mt={2} mb={2}>
                <UserTabComponent usersAndInfo={users} updateRoles={updateRoles}/>
            </Box>
            <Divider sx={{my: 1}}>
                <Typography variant={"h5"}>Création d'un nouvel utilisateur</Typography>
            </Divider>
            <Box mt={2} mb={2}>
                <CreateNewUserComponent user={userLogin} create={createUser}/>
            </Box>
            <Divider sx={{my: 1}}>
                <Typography variant={"h5"}>Suppression d'un utilisateur</Typography>
            </Divider>
            <Box mt={2} mb={2}>
                <DeleteUserComponent users={users} deleteUser={doDelete}/>
            </Box>
        </React.Fragment>
    );
}

