import {BACK_URL, getRequestOptions} from "./ApiService";
import {Vehicle} from "../model/Vehicle";
import {getToken} from "./TokenUtils";

export async function getHistoryById(id: string) {
    try {
        const response = await fetch(
            `${BACK_URL}/history?id=${id}`,
            getRequestOptions("GET", null)
        );
        if (response.ok) {
            return response.json()
        } else {
            return null;
        }
    } catch (e) {
        return null;
    }
}

export async function submitHistory(user: string, vehicle: Vehicle) {
    try {
        const token = getToken();
        const requestOptions = {
            method: "POST",
            headers: {
                'Authorization': 'Bearer ' + token,
                "Content-Type": "application/json",
                "userId": user
            },
            body: JSON.stringify(vehicle, null, 2),
        };
        const response = await fetch(`${BACK_URL}/history`, requestOptions);
        if (response.ok) {
            return response;
        } else {
            return null;
        }
    } catch (e) {
        return null;
    }
}